import user from './user'
import order from './order'
import upload from './upload'
import download from './download'
import factors from './factors'
import type from './type'

export default {
  ...user,
  ...order,
  ...upload,
  ...download,
  ...factors,
  ...type,
}