import request from '@/utils/request'

export default {
  createOrder(data) {
    return request({
      url: '/brand/order',
      method: 'post',
      data
    })
  },
  fetchOrderList(data) {
    return request({
      url: '/brand/order',
      method: 'get',
      params: data
    })
  },

  fetchProcessSelectList() {
    return request({
      url: '/factors/process/type/list',
      method: 'get'
    })
  },

  // pattern 花型操作
  deletePattern(data) {
    return request({
      url: `/order/sub/pattern/${data.patternId}`,
      method: 'delete',
      data
    })
  },
  createPattern(data) {
    return request({
      url: `/order/sub/pattern`,
      method: 'post',
      data
    })
  },
  updatePattern(data) {
    return request({
      url: `/order/sub/pattern/${data.patternId}`,
      method: 'put',
      data
    })
  },
  fetchPatternPicList(data) {
    /**
     * ownerUId=订单所属客户uid
     * timeType = 1 "过去30天",
     * timeType = 2, "30-90天",
     * timeType = 3, "过去90天";
     */
    return request({
      url: `/order/sub/pattern/pic/list`,
      method: 'get',
      params: data
    })
  },
 
}
