import Vue from 'vue'

import App from './App'
import store from './store'

import router from './router'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import i18n from './lang' // internationalization
import './permission' // permission control

import VueClipboard from 'vue-clipboard2'

// if (process.env.NODE_ENV === 'development') { // 本地mock
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// 创建全局EventBus
Vue.prototype.$EventBus = new Vue()

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
