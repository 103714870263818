export default {
  zh: {
    createOrder: 'Create New Order',
    waitAudit: 'To Be Reviewed',
    passed: 'Passed',
    rejected: 'Rejected',
    choosePatternFiles: 'Choose Pattern Files',
    createTips: 'Tips: Please choose your Pattern Flies and enter these necessary information.',
    patternDetail: 'Pattern Detail',
    patternName: 'Pattern Name',
    copyPattern: 'Copy to ALL Patterns',
    deletePattern: 'DELETE This Pattern',
    chooseFabric: 'Choose Fabric',
    chooseProcess: 'Choose Process',
    quantity: 'Quantity',
    processDetail: 'Process Detail',
    type: 'Type',
    size: 'Size',
    fabric: 'Fabric',
    checkForm: 'Please check the form',
    noEmpty: "Can't be empty",
    uploadPattern: 'Upload Pattern',
    clickUpload: 'Click Upload'
  },
  en: {
    createOrder: 'Create New Order',
    waitAudit: 'To Be Reviewed',
    passed: 'Passed',
    rejected: 'Rejected',
    choosePatternFiles: 'Choose Pattern Files',
    createTips: 'Tips: Please choose your Pattern Flies and enter these necessary information.',
    patternDetail: 'Pattern Detail',
    patternName: 'Pattern Name',
    copyPattern: 'Copy to ALL Patterns',
    deletePattern: 'DELETE This Pattern',
    chooseFabric: 'Choose Fabric',
    chooseProcess: 'Choose Process',
    quantity: 'Quantity',
    processDetail: 'Process Detail',
    type: 'Type',
    size: 'Size',
    fabric: 'Fabric',
    checkForm: 'Please check the form',
    noEmpty: "Can't be empty",
    uploadPattern: 'Upload Pattern',
    clickUpload: 'Click Upload'
  }
}