import account from './langModules/account'
import factors from './langModules/factors'
import order from './langModules/order'
import client from './langModules/client'
import download from './langModules/download'

export default {
  route: {},
  navbar: {
    createOrder: 'Create New Order',
    orderList: 'Pre-Order List',
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    logOut: 'Logout',
    userName: 'Username',
    password: 'Password',
    needUserName: 'Please enter the user name',
    needPassword: 'Please enter the password'
  },
  common: {
    reset: 'Reset',
    find: 'Find',
    create: 'Create',
    cancel: 'Cancel',
    enter: 'Enter',
    choose: 'Choose',
    action: 'Action',
    startTime: 'Start Time',
    to: 'To',
    endTime: 'End Time',
    // 状态 操作放在common
    disable: 'Disable',
    usable: 'Usable',
    edit: 'Edit',
    status: 'Status',
    remark: 'Remark',
    orderBy: 'Order By',
    statusTips: 'Are you sure you want to switch states？',
    preview: 'Preview',
    delete: 'Delete',
    save: 'Save'
  },
  dialog: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  message: {
    sucess: 'Successed',
    fail: 'Failed',
    opSuccess: 'Operate Successfully',
    opFail: 'Operation Failure'
  },
  upload: {
    uploadSuc: 'Upload Success',
    uploadFail: 'Upload Fail',
    numLimit: 'Only one file can be uploaded',
    numLimitBatch: 'Files to be uploaded exceeds the maximum',
    typeLimit: 'Uploading files can only be JPEG/JPG',
    // typeLimit: 'Uploading files can only be JPEG/JPG/TIFF/EPS',
    volLimit: 'The size of the uploaded file cannot exceed 150 MB'
  },
  account: account.en,
  factors: factors.en,
  client: client.en,
  order: order.en,
  download: download.en,
  permission: {},
  theme: {},
}
