<!--
 * @Author: chenpengmin
 * @Date: 2022-04-07 15:49:46
 * @LastEditTime: 2022-05-26 20:39:20
 * @LastEditors: fangchang
 * @Description: 主框架
 * @Version: v1.0
-->

<template>
  <div class="page-container">
    <Header />
    <div class="page-title"> {{ generateTitle($route.meta.title) }}</div>
    <!-- <breadcrumb id="breadcrumb-container" /> -->
    <div class="main-container">
      <!-- <Sidebar /> -->
      <router-view />
      <div class="copyright">
        Copyright © 2022 FELINE. All rights reserved.
      </div>
      <el-backtop />
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import { generateTitle } from '@/utils/i18n'

export default {
  components: {
    Header,
  },
  computed: {

  },
  methods: {
    generateTitle
  }
}
</script>

<style lang="scss" scoped>
.page-container{
  margin-top: 60px;
  .main-container{
    min-height: 100%;
    overflow: auto;
    transition: margin-left .25s;
    position: relative;
    top: 20px;
    // margin-left: 200px;
    // padding-top: 60px;
    padding: 0 20px;
  }
  .copyright{
    text-align: center;
    color: #606266;
    padding: 20px 0 40px;
  }
}

.page-title {
  width: 100%;
  display: block;
  font-size: 36px;
  position: relative;
  top: 20px;
  padding: 0 15px 20px 15px;
  border-bottom: 1px solid #ccc;
}
</style>
