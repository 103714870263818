import account from './langModules/account'
import factors from './langModules/factors'
import order from './langModules/order'
import client from './langModules/client'
import download from './langModules/download'

export default {
  route: {},
  navbar: {
    createOrder: 'Create New Order',
    orderList: 'Pre-Order List',
  },
  login: {
    title: '系统登录',
    logIn: '登 录',
    logOut: '退出登录',
    userName: '账号',
    password: '密码',
    needUserName: '请输入账户名',
    needPassword: '请输入密码'
  },
  common: {
    reset: '重置',
    find: '查找',
    create: '创建',
    cancel: '取消',
    enter: '请输入',
    choose: '请选择',
    action: '操作',
    startTime: '开始时间',
    to: '至',
    endTime: '结束时间',
    disable: '停用',
    usable: '启用',
    edit: '编辑',
    status: '状态',
    remark: '备注',
    orderBy: '排序值',
    statusTips: '确认要切换状态吗？',
    preview: '预览',
    delete: '删除',
    save: '保存'
  },
  dialog: {
    confirm: '确 定',
    cancel: '取 消',
  },
  message: {
    sucess: '成功',
    fail: '失败',
    opSuccess: '操作成功',
    opFail: '操作失败'
  },
  upload: {
    uploadSuc: '上传成功',
    uploadFail: '上传失败',
    numLimit: '文件只支持单个上传',
    numLimitBatch: '选择文件超过最大上传数',
    typeLimit: '上传文件只能是 JPEG/JPG 格式',
    // typeLimit: '上传文件只能是 JPEG/JPG/TIFF/EPS 格式',
    volLimit: '上传文件大小不能超过 150MB'
  },
  account: account.zh,
  factors: factors.zh,
  client: client.zh,
  order: order.zh,
  download: download.zh,
  permission: {},
  theme: {},
}
